jQuery(function ($) {
  $(function() {

    initSelect2("#store")
    initSelect2("#category")

    initDatepicker("#from", "#to", "setStartDate")
    initDatepicker("#to", "#from", "setEndDate")

    setChart()

    document.querySelectorAll(".table-sortable th").forEach((headerCell) => {
      headerCell.addEventListener("click", () => {
        const tableElement = headerCell.parentElement.parentElement.parentElement
        const headerIndex = Array.prototype.indexOf.call(
          headerCell.parentElement.children,
          headerCell
        )
        const currentIsAscending = headerCell.classList.contains("th-sort-asc")

        sortTableByColumn(tableElement, headerIndex, !currentIsAscending)
      })
    })

    exportTableData()

  })
})

function initSelect2(selector) {
  $(selector).select2()

  $(selector).on('select2:select', function (e) {
    if (e.params.data.id == 'all') {
      $(selector).val("all").trigger("change")
    }
    else if (
      $(selector).val()[0] == ["all"] &&
      $(selector).val().length > 1
    ) {
      var $select = $(selector).val()
      $select.shift()
      $(selector).val($select).trigger("change")
    }
  })
}

function initDatepicker(firstSelector, secondSelector, eventToHandle){
  $(firstSelector)
    .datepicker({ autoclose: true, format: "yyyy-mm-dd" })
    .on("changeDate", function (e) {
      $(secondSelector).datepicker(eventToHandle, e.date)
    })
}

function setChart() {
  var chartCanvas = $("#chart_canvas")

  if (chartCanvas.data("data") != "") {
    var chart_canvas = new Chart(chartCanvas, {
      type: "bar",
      data: {
        labels: chartCanvas.data("labels"),
        datasets: [
          {
            label: "Percentage",
            data: chartCanvas.data("data"),
            backgroundColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 0.5,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    })
  }
}

function sortTableByColumn(table, column, asc = true) {
  const dirModifier = asc ? 1 : -1
  const tBody = table.tBodies[0]
  const rows = Array.from(tBody.querySelectorAll("tr"))

  const sortedRows = rows.sort((a, b) => {
    const aColText = a
      .querySelector(`td:nth-child(${column + 1})`)
      .textContent.trim()
    const bColText = b
      .querySelector(`td:nth-child(${column + 1})`)
      .textContent.trim()

    return aColText > bColText ? 1 * dirModifier : -1 * dirModifier
  })

  while (tBody.firstChild) {
    tBody.removeChild(tBody.firstChild)
  }

  tBody.append(...sortedRows)

  table
    .querySelectorAll("th")
    .forEach((th) => th.classList.remove("th-sort-asc", "th-sort-desc"))
  table
    .querySelector(`th:nth-child(${column + 1})`)
    .classList.toggle("th-sort-asc", asc)
  table
    .querySelector(`th:nth-child(${column + 1})`)
    .classList.toggle("th-sort-desc", !asc)
}

function exportTableData() {
  $("#export-table-btn").on("click", function () {
    $("#data_table").tableExport({ fileName: 'report' })
  })
}
