$(document).ready(function(){
  $('.datepicker').datepicker();
  $('.select2').select2();
  $("#select-store").find('option').remove().end().append('<option></option>');
  $( "#select-bakery-client" ).change(function() {
    $.ajax({
      url: "/report_generators/populate_data",
      type: "GET",
      dataType: "script",
      data: {
        bakery_client_id: $(this).val()
      },
      success: function(response) {}
    });    
  });
});